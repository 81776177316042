import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import addTaxRegimeAccount from './addtaxRegimeAccount';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'taxRegimeAccount',
  components: {
    addTaxRegimeAccount,
    DatePicker
  },
  watch: {
    currentPage: function() {
      this.getTaxRegimeAccount();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTaxRegimeAccount();
    }
  },
  data() {
    return {
      unsubscribe: null,
      parent_value_set_id: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      payload: {},
      showValueSetModal: false,
      showTaxRegimeAccountModal: false,
      taxRegimeDetails: null,
      totalRows: null,
      loader: false,
      leName: {
        value: null,
        text: null
      },
      regAccType: {
        value: null,
        text: null
      },
      start_date: null,
      end_date: null,
      taxRegimeAccountData: [],
      taxRegimeAccountFields: [
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'tax_name'
        },
        {
          key: 'tax_type'
        },
        {
          key: 'regime_code_meaning',
          label: 'Regime Account'
        },
        {
          key: 'concatenated_segment',
          label: 'Segment'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        }
      ]
    };
  },
  validations: {
    leName: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.getTaxRegimeAccount();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.hideTaxRegimeAccountModal(true);
          this.taxRegimeDetails = null;
        }
        if (actionName === 'download' && !this.showTaxRegimeAccountModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'taxRegimeAccount/getTaxRegimeAccount',
            'taxRegime-account',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getTaxRegimeAccount() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.leName.value,
          segment: this.segment
        };
        this.loader = true;
        this.$store
          .dispatch('taxRegimeAccount/getTaxRegimeAccount', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.taxRegimeAccountData = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    hideTaxRegimeAccountModal(flag) {
      this.showTaxRegimeAccountModal = flag;
    },
    rowSelected(item) {
      this.taxRegimeDetails = item;
      this.showTaxRegimeAccountModal = true;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.org_id,
          text: item.org_name
        };
      } else if (this.vsetCode === 'REGIME_ACCOUNT_TYPE') {
        this.regAccType = {
          value: item.value_set_dtl_id,
          text: item.value_meaning
        };
      }
    },
    clearFilter() {
      this.leName = {
        value: null,
        text: null
      };
      this.regAccType = {
        value: null,
        text: null
      };
      this.start_date = null;
      this.end_date = null;
      this.parent_value_set_id = null;
      this.taxRegimeAccountData = [];
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: null,
          text: null
        };
      } else if (vsetCode === 'REGIME_ACCOUNT_TYPE') {
        this.regAccType = {
          value: null,
          text: null
        };
      }

    }
  },
  beforeDestroy() {
    this.unsubscribe();
    clearTimeout(this.setTimeVsetCode);
  }
};
