export default {
  name: 'addTaxRegimeAccount',
  components: {},
  props: ['taxRegimeDetails'],
  data() {
    return {
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: '',
      editMode: false,
      legalEntity: null,
      taxName: null,
      taxType: null,
      segment: null,
      segment_meaning: null,
      regAccType: null,
      start_date: null,
      end_date: null,
      taxRegimeAccountData: [],
      taxRegimeAccountFields: []
    };
  },
  mounted() {
    if (this.taxRegimeDetails) {
      this.fillRecordFromTaxRegimeAccount();
    } else {
      this.editMode = true;
    }
  },
  methods: {
    fillRecordFromTaxRegimeAccount() {
      // Fill Records From Master Form
      this.legalEntity = this.taxRegimeDetails.le_name;
      this.taxName = this.taxRegimeDetails.tax_name;
      this.taxType = this.taxRegimeDetails.tax_type;
      this.segment = this.taxRegimeDetails.concatenated_segment;
      this.segment_meaning = this.taxRegimeDetails.concatenated_segment_meaning;
      this.regAccType = this.taxRegimeDetails.regime_code_meaning;
      this.start_date = this.taxRegimeDetails.start_date;
      this.end_date = this.taxRegimeDetails.end_date;
    }
  }
};
